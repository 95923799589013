import {
  addAnimationClassOncePartiallyVisible,
  execAfterDomContentLoaded,
  unawaited,
} from "../../util";
import { loadingScreenComplete } from "../loading-screen/loading-screen";

let elMap: {
  titleSceneSection: HTMLElement;
  mdmaTextCont: HTMLElement;
  cloudsLayer: HTMLElement;
  sunLayer: HTMLElement;
  birdsLayer: HTMLElement;
  mintBtn: HTMLElement;
};

const cloudAnimationTime = 100000;

execAfterDomContentLoaded(afterContentLoaded);

async function afterContentLoaded() {
  elMap = {
    titleSceneSection: document.getElementById("title-scene-section"),
    mdmaTextCont: document.getElementById("mdma-text-cont"),
    cloudsLayer: document.getElementById("clouds-layer"),
    sunLayer: document.getElementById("sun-layer"),
    birdsLayer: document.getElementById("birds-layer"),
    mintBtn: document.getElementById("mint-btn"),
  };

  unawaited(loopAnimateClouds());
  unawaited(loopAnimateBirds());

  await loadingScreenComplete;

  await addAnimationClassOncePartiallyVisible(
    elMap.mdmaTextCont,
    "animate__jello",
    true,
    false
  );

  elMap.titleSceneSection.addEventListener("mousemove", onMouseMove);
  elMap.mintBtn.addEventListener("click", () =>
    alert("SOLD OUT - purchase collection on markets such as OpenSea")
  );
}

async function onMouseMove(e: MouseEvent) {
  const xFromCenter = elMap.titleSceneSection.clientWidth / 2 - e.screenX;
  const yFromCenter = elMap.titleSceneSection.clientHeight / 2 - e.screenY;

  const mdmaTextDivisor = 100;
  transformEl(
    elMap.mdmaTextCont,
    `translate(${-(xFromCenter / mdmaTextDivisor)}px,${-(
      yFromCenter / mdmaTextDivisor
    )}px)`
  );

  const sunLayerDivisor = 260;
  transformEl(
    elMap.sunLayer,
    `translate(${xFromCenter / sunLayerDivisor}px,${
      yFromCenter / sunLayerDivisor
    }px)`
  );

  const birdsLayerDivisor = 60;
  transformEl(
    elMap.birdsLayer,
    `translate(${xFromCenter / birdsLayerDivisor}px,${
      yFromCenter / birdsLayerDivisor
    }px)`
  );
}

function transformEl(el: HTMLElement, translateStr: string) {
  el.animate([{ transform: translateStr }], { duration: 5000 });
}

async function loopAnimateClouds() {
  const animationOpts = {
    duration: cloudAnimationTime,
    iterations: 1,
  };
  while (true) {
    await elMap.cloudsLayer.animate(
      [{ transform: "translateX(0)" }, { transform: "translateX(100vw)" }],
      animationOpts
    ).finished;
    elMap.cloudsLayer;
    await elMap.cloudsLayer.animate(
      [{ transform: "translateX(-100vw)" }, { transform: "translateX(0)" }],
      animationOpts
    ).finished;
  }
}

async function loopAnimateBirds() {
  elMap.birdsLayer.firstElementChild.animate(
    [
      {
        transform: "translateY(0)",
      },
      { transform: "translateY(12px)" },
      { transform: "translateY(0)" },
    ],
    { duration: 5000, iterations: Infinity }
  );
  elMap.birdsLayer.lastElementChild.animate(
    [
      {
        transform: "translateY(0) translateX(0)",
      },
      { transform: "translateY(-10px) translateX(4px)" },
      { transform: "translateY(0) translateX(0)" },
    ],
    { duration: 7000, iterations: Infinity }
  );
}
